import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyB9jDUembDYTGHOah1pPVTY2soMpRWvjI0",
  authDomain: "globalfoxtrades-c57bd.firebaseapp.com",
  databaseURL: "https://globalfoxtrades-c57bd-default-rtdb.firebaseio.com",
  projectId: "globalfoxtrades-c57bd",
  storageBucket: "globalfoxtrades-c57bd.appspot.com",
  messagingSenderId: "742357183239",
  appId: "1:742357183239:web:b599c5b685033f5a7ff3a2"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()